import React from "react"
import { GatsbySeo, BlogJsonLd } from "gatsby-plugin-next-seo"
import siteMeta from "@components/site-metadata"
import Layout from "../components/layout"
import Hero from "../components/hero"

export default () => {
  const url = siteMeta().siteUrl

  return (
    <Layout>
      <GatsbySeo
        title="About Us"
        bodyAttributes={{
          class: "bg-black",
          style: {},
        }}
      />

      {/*<BlogJsonLd*/}
      {/*  url={url}*/}
      {/*  // headline={frontmatter.title}*/}
      {/*  // images={coverImage}*/}
      {/*  // posts={[*/}
      {/*  //   {*/}
      {/*  //     headline: "Post 1",*/}
      {/*  //     image: "https://example.com/photos/1x1/photo.jpg",*/}
      {/*  //   },*/}
      {/*  //   { headline: "Post 2" },*/}
      {/*  // ]}*/}
      {/*  datePublished={frontmatter.date}*/}
      {/*  // dateModified="2015-02-05T09:00:00+08:00"*/}
      {/*  authorName={frontmatter.author.name}*/}
      {/*  description={frontmatter.excerpt}*/}
      {/*/>*/}

      <Hero />
    </Layout>
  )
}
